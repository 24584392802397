import React, { useContext } from 'react';
import { Center, Container, Wrap, WrapItem } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image';
import { DbContext } from '../../Services/context';

const Board = () => {
    const { secretary, president, treasurer, sMembers, vp, AT, EACO, CCO, CO, images, organizers, aSecratary, mCoordinator } = useContext(DbContext);



    return (
        <div>
            <Container maxW="100%" bg="gray.900" p={['110px 30px', '80px']} id="about">
                <Center flexDir="column">
                    <Text textAlign="center" fontWeight="bold" textColor="gray.100" fontSize={['4xl', '5xl', '6xl']} letterSpacing="wider">Board of Officials</Text>

                    <Wrap justify="space-around" mt="80px" w={["100vw", "100vw", "80vw"]} alignContent="center">
                        <WrapItem>
                            <Container maxW="340px" mb="-170px">
                                <Center flexDir="column">
                                    <Image src={images[1] && images[0].presidentImg} w="260px" h="260px" objectFit="cover" borderRadius="10px" alt="img" />
                                    {president[0] && <Text textColor="white" fontSize="xl" mt="20px" textAlign="center">{president[0].name}</Text>}
                                    <Text textColor="gray.400" fontSize="lg" mt="10px" textAlign="center">President</Text>
                                </Center>
                            </Container>
                        </WrapItem>
                    </Wrap>

                </Center>

            </Container>

            <Container maxW="100%" bg="gray.900" p={['110px 30px', '80px']} id="about">
                <Center flexDir="column">
                    <Wrap justify="space-around" mt="80px" w={["100vw", "100vw", "80vw"]} alignContent="center">
                        <WrapItem>
                            <Container maxW="300px" mb="50px">
                                <Center flexDir="column">
                                    <Image src={images[0] && images[1].secretaryImg} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" />
                                    {secretary[0] && <Text textColor="white" fontSize="xl" mt="20px" textAlign="center">{secretary[0].name}</Text>}
                                    <Text textColor="gray.400" fontSize="lg" mt="10px" textAlign="center">{secretary.length > 1 ? "Joint Secretary" : "Secretary"}</Text>
                                </Center>
                            </Container>
                        </WrapItem>

                        <WrapItem>
                            <Container maxW="300px" mb="50px">
                                <Center flexDir="column">
                                    <Image src={images[2] && images[2].treasurerImg} w="220px" h="220px" objectFit="cover" borderRadius="10px" alt="img" />
                                    {treasurer[0] && <Text textColor="white" fontSize="xl" mt="20px" textAlign="center">{secretary.length > 1 ? secretary[1].name : treasurer[0].name}</Text>}
                                    <Text textColor="gray.400" fontSize="lg" mt="10px" textAlign="center">{secretary.length > 1 ? "Joint Secretary" : "Treasurer"}</Text>
                                </Center>
                            </Container>
                        </WrapItem>
                    </Wrap>

                </Center>

            </Container>

            <Container maxW="100%" bg="gray.900" p="0px 80px 80px 80px">
                <Center flexDir={["column", "column", "row"]}>
                    <Wrap mt="20px" w="80vw" justify="space-between" spacing={["50px", "80px", "150px"]}>


                        <WrapItem>
                            <Container>
                                <Text textColor="white" fontSize="xl">{vp[0] && vp[0].name}</Text>
                                <Text textColor="gray.400" fontSize="md">{vp && vp.length > 1 ? "Vice Presidents" : "Vice President"}</Text>
                            </Container>
                        </WrapItem>


                        {secretary.length === 2 ?
                            <WrapItem>
                                <Container>
                                    <Text textColor="white" fontSize="xl">{treasurer[0] && treasurer[0].name}</Text>

                                    <Text textColor="gray.400" fontSize="md">{secretary.length > 1 ? "Treasurer" : ""}</Text>
                                </Container> </WrapItem> : null}

                        <WrapItem>
                            <Container>
                                {aSecratary[0] && aSecratary.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Assistant Secratary</Text>
                            </Container>
                        </WrapItem>


                        <WrapItem>
                            <Container>
                                {AT[0] && AT.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Assistant Treasurer</Text>
                            </Container>
                        </WrapItem>


                        <WrapItem>
                            <Container>
                                {CO[0] && CO.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Chief Organizer</Text>
                            </Container>
                        </WrapItem>



                        <WrapItem>
                            <Container>
                                {CCO[0] && CCO.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Chief Co-Ordinator</Text>
                            </Container>
                        </WrapItem>

                        <WrapItem>
                            <Container>
                                {organizers[0] && organizers.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Organizers</Text>
                            </Container>
                        </WrapItem>

                        <WrapItem>
                            <Container>
                                {mCoordinator[0] && mCoordinator.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Editor</Text>
                            </Container>
                        </WrapItem>
                        {/* Media Coordinator > changed as Editor */}

                        <WrapItem>
                            <Container>
                                {EACO[0] && EACO.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Auditors</Text>
                            </Container>
                        </WrapItem>
                        {/* External Affiars Co-Ordinator > changed as Auditor */}

                        <WrapItem>
                            <Container>
                                {sMembers[0] && sMembers.map((member) => {
                                    return (
                                        <Text textColor="white" fontSize="xl">{member.name}</Text>
                                    )
                                })}
                                <Text textColor="gray.400" fontSize="md">Committee Members</Text>
                            </Container>
                        </WrapItem>
                        {/* Senior Member > changed as Committee Members */}
                    </Wrap>
                </Center>
            </Container>
        </div>
    )
}

export default Board