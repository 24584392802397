/* eslint-disable no-unused-vars */
import {
    Heading,
    Link,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Box,
    Input,
    Button,
    Radio, RadioGroup, VisuallyHidden, color,
} from '@chakra-ui/react'
import { Center, Container, Text, VStack, HStack } from '@chakra-ui/layout'
import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion'
import image from '../../Images/homeBG.jpg'
import { ChevronDownIcon } from '@chakra-ui/icons'
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';
import { db } from '../../Services/firebase';
import { doc, deleteDoc, setDoc, updateDoc, collection, } from "firebase/firestore";
import { DbContext } from '../../Services/context';
import { useToast } from '@chakra-ui/toast';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { getAllByTestId } from '@testing-library/react';
import { Select } from '@chakra-ui/select';
import './BrainiacBlitz_Registration.css';

const Registration = () => {
    const toast = useToast();

    const [tabIndex, setTabIndex] = useState(0)

    const handleTabsChange = (index) => {
        setTabIndex(index);
    }

    const [preValuesTic, setPreValuesTic] = useState(['', '']);
    const [preValuesCaptain, setPreValuesCaptain] = useState(['', '', '', '', '']);
    const [preValuesMem2, setPreValuesMem2] = useState(['', '', '', '', '']);
    const [preValuesMem3, setPreValuesMem3] = useState(['', '', '', '', '']);
    const [preValuesMem4, setPreValuesMem4] = useState(['', '', '', '', '']);


    // You can update the array using the setMyArray function.
    const updatePreValues = (arrayName, value1, value2, value3, value4, value5) => {
        if (arrayName === 'preValuesTic') {
            setPreValuesTic([value1, value2]);
        } else if (arrayName === 'preValuesCaptain') {
            setPreValuesCaptain([value1, value2, value3, value4, value5]);
        } else if (arrayName === 'preValuesMem2') {
            setPreValuesMem2([value1, value2, value3, value4, value5]);
        } else if (arrayName === 'preValuesMem3') {
            setPreValuesMem3([value1, value2, value3, value4, value5]);
        } else if (arrayName === 'preValuesMem4') {
            setPreValuesMem4([value1, value2, value3, value4, value5]);
        }
    };

    const handleCategoryChange = (index) => {
        let btn = document.getElementById(index);
        document.getElementById('btn1').className = 'btn_style1';
        document.getElementById('btn2').className = 'btn_style1';
        document.getElementById('btn3').className = 'btn_style1';
        document.getElementById('btn4').className = 'btn_style1';
        btn.className = 'btn_style2'
        const radio_placeholder = document.getElementById('placeHolder');
        radio_placeholder.checked = true;
    }

    return (
        <Container id='brainiacBlitz_register'>
            <div id='register_background'></div>
            <div id='register_wrapper'>
                <Container id='size_control'>
                    <p style={{ textAlign: "center", fontSize: "40px", fontWeight: "500", padding: "10px 0 10px 0" }}>Registration</p>
                    <p style={{ textAlign: "center", fontSize: "13px" }}>ALL QUESTIONS ARE REQUIRED. <a target='_blank' rel="noreferrer" id='links_registartion_form' href='/bb24/r&r'>SEE RULES & REGULATIONS</a>.</p>
                    <p style={{ textAlign: "center", fontSize: "13px", padding: "0 0 30px 0" }}>FOR ANY INQUIRY <a id='links_registartion_form' href='#brainiacBlitz_contact'>CONTACT US</a>.</p>
                    <Formik
                        initialValues={{
                            email: '',
                            fullName: '',
                            school: '',
                            birthday: '',
                            telno: '',
                            category: '',
                            submissionTime: '',
                        }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            try {
                                const currentDate = new Date();

                                const seconds = currentDate.getSeconds();
                                const minutes = currentDate.getMinutes();
                                const hours = currentDate.getHours();
                                const date = currentDate.getDate();
                                const month = currentDate.getMonth();
                                const year = currentDate.getFullYear();

                                const full_date = date + "/" + (month + 1) + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
                                const full_date_2 = date + "-" + (month + 1) + "-" + year + "_" + hours + ":" + minutes + ":" + seconds;

                                values.submissionTime = full_date;

                                var document_ID = full_date_2 + "_" + values.email;

                                setDoc(doc(db, "BrainiacBlitzRegistration", document_ID), values);
                                setSubmitting(false);
                                resetForm();
                                const btn1 = document.getElementById('btn1');
                                const btn2 = document.getElementById('btn2');
                                const btn3 = document.getElementById('btn3');
                                const btn4 = document.getElementById('btn4');
                                btn1.className = 'btn_style1';
                                btn2.className = 'btn_style1';
                                btn3.className = 'btn_style1';
                                btn4.className = 'btn_style1';
                                const radio_placeholder = document.getElementById('placeHolder');
                                radio_placeholder.checked = false;
                                handleTabsChange(tabIndex + 1);
                            } catch (error) {
                                toast({
                                    title: 'OOPS, something happened',
                                    description: error.message,
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Tabs index={tabIndex} onChange={handleTabsChange}>
                                    <TabPanels>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <label id='formLabel' htmlFor="email">Email</label>
                                                <Input
                                                    name="email"
                                                    placeholder="Your Email"
                                                    type="email"
                                                    value={values.email} onChange={handleChange} onBlur={handleBlur}
                                                    isRequired
                                                    id='formInput'
                                                />

                                                <label id='formLabel' htmlFor="fullName">Full Name</label>
                                                <Input id='formInput' name="fullName" placeholder="Your Full Name" value={values.fullName} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label id='formLabel' htmlFor="school">School</label>
                                                <Input id='formInput' name="school" placeholder="Your School" value={values.school} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label id='formLabel' htmlFor="birthday">Birthday</label>
                                                <Input id='formInput' type="date" name="birthday" min="2005-01-31" max="2014-01-31" value={values.birthday} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label id='formLabel' htmlFor="telno">WhatsApp No (Tel No)</label>
                                                <Input id='formInput' name="telno" placeholder="Your Whatsapp No" value={values.telno} onChange={handleChange} onBlur={handleBlur} isRequired />

                                                <label id='formLabel'>Category</label>
                                                <div id='btn_container'>
                                                    <Button id='btn1' class='btn_style1' onClick={() => {
                                                        handleCategoryChange('btn1');
                                                        values.category = 'Arts and Literature';
                                                    }}>Arts and Literature<input type="radio" id="placeHolder" name="category" value="placeHolder" required /></Button>
                                                    <Button id='btn2' class='btn_style1' onClick={() => {
                                                        handleCategoryChange('btn2');
                                                        values.category = 'Sports and Games';
                                                    }}>Sports and Games</Button>
                                                    <Button id='btn3' class='btn_style1' onClick={() => {
                                                        handleCategoryChange('btn3');
                                                        values.category = 'Science And Technology';
                                                    }}>Science And Technology</Button>
                                                    <Button id='btn4' class='btn_style1' onClick={() => {
                                                        handleCategoryChange('btn4');
                                                        values.category = 'History and Geography';
                                                    }}>History and Geography</Button>
                                                </div>
                                                {/* <input type="radio" id="rules_and_regulation" required /> */}
                                                <br />
                                                <label id='formLabel' htmlFor='rules_and_regulation'>By submitting this form, I agree to all <a target='_blank' rel="noreferrer" id='links_registartion_form' href='/bb24/r&r'>rules and regulations</a>.</label>
                                                <br />

                                                <div id='action_btn_container'>
                                                    <Button id='formBtnSubmit' type="submit" disabled={isSubmitting}>Submit</Button>
                                                    {/* <Button type="reset" >Reset</Button> */}
                                                </div>
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack>
                                                <p style={{ textAlign: "center" }}>---<br />Thank you for registering with BrainiacBlitz.<br />You will be added to a WhatsApp group in a few days.<br />---</p>
                                            </VStack>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Form>)}
                    </Formik>
                </Container >
            </div>
        </Container>
    )
}

export default Registration
